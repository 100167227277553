/* GLOBAL */

/* TODO: Stick footer to bottom of page */
/* .contents {
    min-height: calc(100vh - 38px);
} */

/* MAIN */

.logo {
    animation: fadeIn 1.5s;
    -webkit-animation: fadeIn 1.5s;
    -moz-animation: fadeIn 1.5s;
}

.check-it-out {
    display: flex;
    justify-content: right;
    padding: 10px;
    opacity: 0;
    animation: blinker 1s linear infinite;
    -webkit-animation: blinker 1s linear infinite;
    -moz-animation: blinker 1s linear infinite;
    animation-delay: 3s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

@keyframes blinker {
    0% {opacity:0;}
    50% {opacity:1;}
    100% {opacity: 0;}
}

/* CONTACT */

.scene {
    width: 200px;
    height: 200px;
    perspective: 600px;
}



/* HEADER */
#navbar-logo {
    max-height: 50px;
    /* position: absolute;
        top: 0px; right: 0px; */

}

/* FOOTER */

/* TODO: Stick footer to bottom of page */
/* .footer {
    background: linear-gradient(rgba(255,0,0,0.5), rgba(0,0,255,0.5));
    padding: 10px 0px 10px 0px;
} */

/* MEDIA QUERIES */

@media (min-width: 480px) {

}

@media (min-width: 769px) {

}

@media (min-width: 992px) {
    .check-it-out {
        justify-content: left;
    }
}